@font-face {
  font-family: "Sting,Helvetica,sans-serif";
  src: local("Sting,Helvetica,sans-serif"),
    url("./fonts/Roboto-Font/Roboto-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "sting-bold";
  src: local("sting-bold"), url("./fonts/Roboto-Font/Roboto-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "sting-light";
  src: local("sting-light"), url("./fonts/Roboto-Font/Roboto-Light.woff2") format("woff2");
}
.step-subscription-plan-selector.home-data ul {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr 1fr !important;
  grid-gap: 10px !important;
}
.subscription-plan-home {
  display: none;
}
.transation-ease-in-out {
  transition: 0.3s ease-in-out;
}
.font-face-sh {
  font-family: "Sting,Helvetica,sans-serif";
}
.notfound-button:hover {
  background-color:  #49a2f1 !important;
}
.hero-background-gradient {
  background: linear-gradient(135deg, #151c36, #151c36 52%, #fff 0, #fff);
}
.font-face-sh-bold {
  font-family: "sting-bold";
}
.font-face-sh-light {
  font-family: "sting-light";
}
.col-md-6.col-sm-6.col-lg-6.prize-list {
  color: #fff;
}
/* .stories-section.bg-branding-2 {
  background: linear-gradient( #00000063, #020202), url('./images/thankyou.jpg');
  padding: 40px;
background-position: center;
} */
.title-prizes {
  border-bottom: 2px solid #fff;
}
/***Custom*/

.title-managed::before {
  content: "";
  background-color: #49a2f1;
  position: absolute;
  height: 100%;
  z-index: -1;
  left: 25%;
  width: 50%;
}
.story-block {
  display: block !important;
  margin: 0 auto;
}
.custom-arrow.prev-arrow::before, .story-block-stats p strong:before {
  background-repeat: no-repeat;
  bottom: 40%;
  content: "";
  position: absolute;
  z-index: 999;
}

.prize-block.prize-3b821825-f0a5-4dac-9498-b34125ff8bfe .prize-photo {
  background-size: contain !important;
  background-repeat: no-repeat;
  position: relative; /* Add this to make ::before pseudo-element work */
}



/* Rest of your styling */
.prize-block.prize-3b821825-f0a5-4dac-9498-b34125ff8bfe .prize-photo {
  background-color: #fff;
}


.story-block-stats p strong:before {
  background-image: url('./images/trophy.svg');
  background-size: contain;
  height: 30px;
  left: -45px;
  top: 0%;
  width: 30px;
}
#example-collapse-text p {
  text-align: left;
  padding: 0px 20px;
}
#example-collapse-text ul {
  text-align: left;
margin-left: 20px;
color: #49a2f1;
margin-right: 20px;
}
.custom-arrow.prev-arrow::before {
  background-image: url('./images/prev-arrow.png');
  background-size: contain;
  height: 40px;
  left: -65px;
  right: 0;
  top: 50%;
  width: 40px;
}
.custom-arrow.next-arrow::before {
  background-image: url('./images/next-arrow.png');
  background-repeat: no-repeat;
  background-size: 40px;
  background-size: contain;
  bottom: 40%;
  content: "";
  height: 40px;
  position: absolute;
  right: -65px;
  top: 50%;
  width: 40px;
  z-index: 999;
}
.description-data.py-5::before {
  background: #fff;
  content: "";
  height: 100%;
  left: 100%;
  position: absolute;
  top: 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 150vw;
  z-index: -9;
}
.how-it-works_section.font-face-sh.bg-position.py-5 * {
  color: #fff;
}
.banners-bees, .mission-section.font-face-sh {
  overflow: hidden;
}

.branding-1 {
  color: #151c36;
}
.branding-1-hover:hover {
  color: #151c36 !important;
}
.bg-branding-green {
  background-color: #05b304;
}
.bg-branding-green:hover {
  background-color: #05b304 !important;
}
.btn:first-child:hover, :not(.btn-check) + .btn:hover {
  
  background-color:  #dfd202 !important;
}
.bg-branding-btn {
  background-color: #151c36;
}
.bg-branding-btn-sec {
  background: linear-gradient(#151c36,#49a2f1);
}
.bg-branding-btn-sec-revert {
  background: linear-gradient(#49a2f1,#49a2f170);
}
.bg-branding-btn:hover {
  background-color: #151c36 !important;
}
.ticket-title-sec.px-3.py-5.position-relative {
  background-image: url('./images/Ticket.png');
  background-size: 100% 100%;
}
.branding-1-light-bg {
  background-color: #ff1c25;
}
.bg-branding-1 {
  background-color: #151c36;
}
.bg-branding-1-hover:hover {
  background-color: #151c36 !important;
 }
.border-branding-1 {
  border-color: #151c36 !important;
}
.branding-2 {
  color: #49a2f1;
}
.bg-branding-2 {
  background-color: #49a2f1;
}
.branding-3 {
  color: #302d51;
}
.branding-5 {
  color: #b4a0c7;
}
.bg-green-clr {
  background-color: #05b304;
}
.bg-green-clr:hover {
  background-color: #05b304;
}
.black-clr {
  color: #151c36;
}
.bg-position {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.rounded-3 {
  border-radius: 12px !important;
}
.z-index-9 {
  z-index: 99999;
}

.play-icon, .fund-icon, .win-icon {
  font-size: 40px;
}
 .play-icon.position-relative::after {
  content: "";
  background-image: url('./images/play-icon.png');
  width: 28px;
  height: 28px;
  background-size: contain;
  display: block;
position: absolute;
right: -30px;
top: 0;
}

.fund-icon.position-relative::after {
  content: "";
  background-image: url('./images/fund-icon.png');
  width: 28px;
  height: 28px;
  background-size: contain;
  display: block;
position: absolute;
right: -30px;
top: 0;
}

.win-icon.position-relative::after {
  content: "";
  background-image: url('./images/win-icon.png');
  width: 28px;
  height: 28px;
  background-size: contain;
  display: block;
position: absolute;
right: -30px;
top: 0;
}


.modal-header .btn-close {
  position: absolute;
  right: 15px;
}
.fade.modal.show, .modal-backdrop.show {
  z-index: 99999;
}

.herosec-img {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  --gradientSize: 99vw 99%;
  background-image: linear-gradient( #6daac837, #151c36),
    url("./images/AFC_Totton_Champions.jpg");
}

.container {
  max-width: 1200px !important;
  padding: 0px 30px;
}
.container-2 {
  max-width: 1030px !important;
  margin: 0 auto;
}
.checkbox-item input[type="date"], .dob-field input[type="date"] {
  appearance: none;
  -moz-appearance: none;
}
.dob-field {
  position: relative;
}
.dob-field label {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto !important;
  padding: 16px 30px;
  background-color: #F9F9FB;
  border-radius: 50px;
  cursor: pointer;
}
.dob-field input:focus + label {
  opacity: 0 !important;
  display: none !important;
}
.dob-field input {
  cursor: pointer;
}
.prizes-section {
  overflow: hidden;
 }
.prizes-section::before {
  content: "";
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: -1;
  background-repeat: no-repeat;
  --gradientSize: 117vw 98%;
  background-image: radial-gradient(
    ellipse var(--gradientSize) at top,
    #151c36 0,
    #151c36 99.9%,
    #ffffff 100%
  );
  width: 100vw;
  height: 72%;
  top: -17px;
  left: 50%;
  background-position: top;
  background-size: 100% auto;
  --gradientSize: 77vw 80%;
  padding-top: 80px;
}

.slick-dots {
  bottom: -155px;
  width: auto;
  margin: auto;
  list-style: none;
  text-align: center;
  background-color: #fff;
  display: flex !important;
  box-shadow: 0 4px 28px rgba(var(--color-black-rgb), 0.16);
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  border-radius: 10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  padding: 0 14px;
  z-index: 99;
  height: 24px;
}
.slick-dots li {
  margin: 0;
  width: 12px;
}
.slick-dots li button {
  width: 12px;
  padding: 0;
}
.slick-dots li button::before {
  width: 12px;
  color: gray;
  opacity: 1;
  height: 12px;
  border-radius: 50%;
}
.slick-dots li.slick-active button::before {
  opacity: 1;
  color: #151c36;
  font-size: 9px;
}
.light-border {
  border: 1px solid hsla(0, 0%, 100%, 0.25);
}
.footer-section::after {
  position: absolute;
  z-index: -1;
  bottom: -11px;
  display: block;
  width: 100%;
  height: 132px;
  content: "";
  background-image: url("./images/wave.svg");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  height: 332px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

/* Side Bar */
.sidebar {
  transition: 0.5s ease-in-out;
  opacity: 0;
}
.sidebar.open {
  width: 100%;
  opacity: 1;
  z-index: 1030;
}

.w-0 {
  width: 0;
}

.MenuButton_root {
  position: relative;
  top: 0px;
  display: block;
  width: 40px;
  height: 23px;
  -webkit-transform: rotate(-5deg) scale(0.93);
  transform: rotate(-5deg) scale(0.93);
  right: -1px;
}
.MenuButton_firstLine {
  position: absolute;
  width: 43px;
  height: 5px;
  background: #ffffff;
  transition: 0.25s;
  right: 0;
}
.MenuButton_secondLine {
  position: absolute;
  top: 9px;
  right: 0;
  width: 33px;
  height: 5px;
  background: #ffffff;
  -webkit-transform: rotate(1deg);
  transform: rotate(1deg);
  transition: 0.25s;
}
.MenuButton_thirdLine {
  position: absolute;
  top: 18px;
  right: 0;
  width: 33px;
  height: 5px;
  background: #ffffff;
  -webkit-transform: rotate(-1deg);
  transform: rotate(-1deg);
  transition: 0.25s;
  opacity: 1;
}
.MenuButton_rootActive {
  position: relative;
  -webkit-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  z-index: 99999;
}
.MenuButton_firstLineActive {
  top: 0px;
  right: 0;
  height: 4px;
  width: 30px;
  background-color: #000;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
}
.MenuButton_secondLineActive {
  right: 0;
  width: 30px;
  height: 4px;
  background-color: #000;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.MenuButton_thirdLineActive {
  opacity: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}


/*Subscription Plan*/
.fs-22 {
  font-size: 22px;
}
.input {
  background: #f2f2f2;
  border-radius: 50px;
  height: 56px;
  border: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 30px;
  width: 100%;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #4F6076;
  outline: none;
  margin-top: 0px;
}
.input::placeholder {
  opacity: 0.7;
}
.terms {
  font-size: 13px;
  text-align: center;
  margin-top: 20px;
  line-height: 1.6;
  color: #43465E;
}
.terms-link {
  font-weight: 800;
  text-decoration: none;
  color: #43465E;
}
.ticket-title-sec:before {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #dfdfdf;
  position: absolute;
  top: 10px;
  left: 9px;
  z-index: 1;
}
/* .ticket-title-sec.active:before {
  border-color: #151c36;
}
.ticket-title-sec.active:after {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #071488;
  position: absolute;
  top: 15px;
  left: 14px;
  z-index: 1;
} */



.step-subscription-plan-selector ul li.active .ticket-title-sec::before {
  border-color: #49a2f1;
}
.step-subscription-plan-selector ul li.active .ticket-title-sec:after {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #49a2f1;
  position: absolute;
  top: 15px;
  left: 14px;
  z-index: 1;
}
.step-subscription-plan-selector ul li.active .ticket-price {
  background-color: #49a2f1 !important;
  color: #ffffff;
}
.draw-entries-icon:before {
  content: "";
  background-color: #e8e6f7;
  top: 9px;
  left: 9px;
  width: 34px;
  height: 34px;
  border-radius: 17px;
  position: absolute;
}
.draw-entries-icon:after {
  -webkit-mask: url('./images/pound.svg');
  mask: url('./images/pound.svg');
  background-color: #6259d2;
    content: "";
    min-width: 22px;
    max-width: 22px;
    height: 22px;
    position: absolute;
left: 0;
right: 0;
top: 0;
bottom: 0;
margin: auto;
}

/* Terms and condition */

    .terms-conditions-text {
      padding: 50px 24px;
      max-width: 1110px;
      margin: auto;
      font-size: 16px;
      font-family: "Sting,Helvetica,sans-serif";
    }

    .terms-conditions-text h1 {
      font-size: 32px;
      margin: 0px;
      padding: 0px;
      font-family: "Sting,Helvetica,sans-serif";
    }
    .terms-conditions-text h2{
      font-size: 24px;
      margin: 0px;
      padding: 0px;
      font-family: "Sting,Helvetica,sans-serif";
    } 
    /* .site-header.siteHeaderColorDark.position-sticky {
      background: #fff;
    } */

.ul-tick li:before {
  content: "";
  vertical-align: bottom;
  min-width: 16px;
  max-width: 16px;
  height: 16px;
  display: inline-block;
  -webkit-mask: url('./images/check.svg');
  mask: url('./images/check.svg');
  background-color: #05b304;
  margin-right: 6px;
}
.input-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.input-radio input:checked~.input-radio-label {
  background-color: #000;
  color: #fff;
}
.input-radio .input-radio-label {
  background: #f8f8f8;
  height: 48px;
  display: grid;
  -webkit-align-content: center;
  align-content: center;
  padding: 0 24px;
  border-radius: 50px;
  color: rgba(var(--color-black-rgb),.5);
  font-size: 14px;
}
.summary-info-impact .summary-info-impact_title::before {
  content: "";
  background-image: url('./images/tick.svg');
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  background-size: contain;
background-repeat: no-repeat;
}
.input-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.input-checkbox_check {
  height: 24px;
  min-width: 24px;
  max-width: 24px;
  margin-right: 8px;
  background-color: rgba(10,213,96,.05);
  border: 1px solid rgba(10,213,96,.7);
  border-radius: 16px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  cursor: pointer;
}
.input-checkbox input:checked~.input-checkbox_check:after {
  width: 22px;
  height: 22px;
}
.input-checkbox input:checked~.input-checkbox_check:after {
  content: "";
  width: 18px;
  height: 18px;
  background-image: url('./images/checkbox-tick.svg');
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: rgba(10,213,96);
  border-radius: 20px;
}
.input-checkbox-label {
  color: #302d51;
  font-size: 15px;
  line-height: 25px;
  cursor: pointer;
}
.bg-grey {
  background-color: #f6f6f6;
}
.fs-14 {
  font-size: 14px;
}
.fs-18 {
  font-size: 18px;
}
.navigation-item.active .navigation-item-link {
  box-shadow: 0 0 11px rgba(7,20,136,.77);
}
.home .dropdown #dropdown-menu-align-end {
  background-color: transparent;
  border: none;
  color: rgb(255, 255, 255);
  font-weight: 700;
  font-size: 1.1rem;
}
.dropdown #dropdown-menu-align-end, .open.sidebar .dropdown #dropdown-menu-align-end {
  background-color: transparent;
  border: none;
  color: #fff;
  font-weight: 700;
  font-size: 1.1rem;
}
.dropdown #dropdown-menu-align-end::after {
  display: none;
}
.dropdown-menu.show.dropdown-menu-end {
  box-shadow: 0 0 20px -5px rgba(0,0,0,.3);
  border-radius: 0px;
  border: none;
  background-color: #fff;
  transform: translate(0px, 59px) !important;
  transition: opacity .5s;
}
.dropdown-menu.show.dropdown-menu-end .dropdown-item {
  font-size: 1.1rem;
  color: #585858;
  padding: 6px 20px;
line-height: 1.5rem;
}
.dropdown-menu.show.dropdown-menu-end .dropdown-item:hover {
  opacity: 0.5;
}
.dropdown-menu.show.dropdown-menu-end:after {
  border-bottom: 8px solid #ffffff;
  top: -6px;
}
.dropdown-menu.show.dropdown-menu-end:after, .dropdown-menu.show.dropdown-menu-end:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  right: 30px;
}
.free-entry-modal_title:after {
  content: "";
  background-image: url('./images/title-bg.svg');
  background-size: cover;
  position: absolute;
  width: 414px;
  height: 414px;
  left: 0;
  right: 0;
  top: -5px;
  margin: auto;
}
.step-subscription-plan-selector ul {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.Toastify {
  z-index: 9999999;
  position: relative;
}
.PhoneInputInput, .PhoneInputInput:focus-visible{
  border: none !important;
  outline: none !important;
  background-color: #f2f2f2;
}

@media only screen and (min-width: 1000px) {
.prizes-sliders .single-slider {
  width: 60%;
  margin: 0 auto;
}
}

@media only screen and (max-width: 991px) {
  .terms-conditions-text {
    overflow: hidden;
    word-wrap: anywhere;
  }
.desktop-menu {
  display: none !important;
}
.mobile-menu {
  display: flex !important;
}
.site-header {
  /* background-color: #000; */
  z-index: 99999;
position: relative;
}
}

@media only screen and (max-width: 1300px) {
  .custom-arrow.prev-arrow::before {
    left: -15px;
  }
  .custom-arrow.next-arrow::before {
    right: -15px;
  }
}



@media only screen and (max-width: 991px) {
  .description-data.py-5::before {
    transform: translateX(-90%);
  }
  .desktop-menu {
    display: none !important;
  }
  .mobile-menu {
    display: flex !important;
  }
  .site-header {
    /* background-color: #000; */
    z-index: 99999;
  position: relative;
  }
  .site-header.dashboard-header {
    background-color: #ffffff;
  }
  .account-navigation .navigation-item.settings {
    display: none !important;
  }
  .dashboard-menu {
    display: none !important;
  }
  .dashboard-header .MenuButton_firstLine, .dashboard-header .MenuButton_secondLine, .dashboard-header .MenuButton_thirdLine {
    background: #000;
  }
  .for-mobile {
    display: block !important;
  }
  }

@media only screen and (max-width: 767px) {
  .notfound {
    width: 100% !important;
  }
  .herosec-img {
    /* background-image: radial-gradient(100% 110% at top, #0000 100%,#fff 0% 0%),url("./images/HeroBg.png"); */
    /* background-image: linear-gradient( #6daac863, #151c36), */
    /* url("./images/mobile.jpg"); */
  }
  .prizes-sliders img {
    height: 300px;
  }
  .notfound h1 {
    font-size: 40px !important;
  }
  .notfound-page {
    padding: 30px 0px !important;
  }
  .box-logo.bg-dark.rounded-5.py-5.px-3.text-center.shadow-sm.position-relative {
    width: 50%;
    margin: 0 auto;
}
.logo-thank-you .share-icons.w-50 {
  width: 30% !important;
}
.col-md-8.align-middle.pb-md-0.pb-4.font-face-gb.thank-you-title {
  text-align: center;
  margin-bottom: 30px;
}
.container-fluid.thank-home.home-left-side.mb-md-0 {
  padding-top: 500px !important;
  padding-bottom: 40px !important;
}
.thankyou-header {
  background-size: unset !important;
}




  .wt-100 {
    width: 100% !important;
  }
  .matchcard-logosblock {
    padding: 30px 15px !important;
  }
  .previous-winners_section .container {
    padding: 0;
  }
  .match-card {
    margin: 0 !important;
  }
  .matchcard-header img {
    width: 140px !important;
  }
  .desktop-btn {
    display: none;
  }
  .mobile-bottom {
    display: block !important;
  }
  .mobile-bottom button {
    width: 100% !important;
  }
  .hero-section {
    padding-bottom: 0px !important;
  }
  .stories-section-title {
    margin-top: 0px !important;
  }
  .stories-container {
    width: 100% !important;
  }
  .stories-container {
    width: 100% !important;
    border: none;
    padding: 0 !important;
    text-align: center;
  }
  .story-block-stats .d-flex.align-items-center {
    justify-content: center;
  }
  .story-block-testimonial h4 {
    margin-top: 30px;
  }
  .story-photo img {
    width: 108px !important;
    margin: 0 auto;
  }
  .curve-dot {
    bottom: -39px !important;
  }
  .story-block-inner {
    padding-bottom: 95px !important;
  }
  .slick-dots {
    bottom: -60px;
  }
  .prizes-section_container {
    width: 89% !important;
  }
  .prizes-section_blockcontainer_sec {
    margin-left: -24px;
    margin-right: -24px;
  }
  .prizes-section-prizes_container {
    margin-left: -24px !important;
    margin-right: -24px !important;
    margin-top: 20px !important;
  }
  .prizes-section-prizes_container h3 {
    font-size: 20px !important;
  }
  .prizes-section-prizes_container .col-md-6 {
    width: 50%;
  }
  .how-it-works_section .col-md-4 P {
    margin-top: 30px !important;
    margin-bottom: 40px;
  }
  .container-2 {
    max-width: 89% !important;
    margin: 0 auto;
  }
  .charity_section p.m-auto {
    margin: 0 !important;
    width: 100% !important;
    margin-top: 20px !important;
  }
  .charity_section .col-sm-12.col-md-12.col-lg-6.pe-5 {
    padding-right: 12px !important;
  }
  .charity_section button {
    width: 100% !important;
    margin-top: 30px !important;
    margin-bottom: 50px !important;
  }
  .bg-branding-green {
    width: 100% !important;
  }
  .container {
    padding: 0px 20px;
  }
  .prizes-section::before {
    width: 100vw;
    height: 97%;
  }
  .subscription-plan-container {
    max-width: 375px !important;
  }
  .step-subscription-plan-selector ul {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
  }
  .subscription-plan-price_currency {
    margin-top: 5px !important;
  }
  .ticket-title-sec img {
    height: 55px !important;
  }
  .ticket-title-sec h4 {
    font-size: 16px !important;
  }
  .ticket-title-sec {
    padding-bottom: 20px !important;
  }
  .StepSubscriptionPlan_topBackground {
    height: 300px !important;
    border-radius: 0px 0px 78px 78px;
    background-color: #000;
  }
  .StepSubscriptionPlan_topBackground path {
    display: none;
  }
  .subscription-plan-container .step-user-details-info {
    padding-left: 0px;
    margin-top: 0 !important;
  }
  .subscription-plan-container .step-user-details {
    padding-right: 0 !important;
    padding-left: 0;
  }
  }

  @media only screen and (max-width: 375px) {
  .subscription-plan-price_amount.branding-1.display-3.font-face-sh-bold {
    font-size: 22px;
  }
  .subscription-plan-price_currency.branding-1.fs-2.font-face-sh-bold.mt-4.me-1 {
    font-size: 18px !important;
    margin-top: 0px !important;
  }
}

@media only screen and (max-width: 479px) {
  .subscription-plan-selector-entries.d-flex.align-items-center {
    display: block !important;
    text-align: center;
  }
  .account-navigation.py-5 {
    margin: 0px 20px;
  }
  .subscription-plan-emphasis.text-white.d-inline-block.lh-normal.position-absolute.start-0.end-0.m-auto {
    background-color: #24284d !important;
  }
  .mission-section.font-face-sh .container .col-lg-5 {
    padding: 0px 30px;
  }
  .mission-section.font-face-sh .container .col-lg-7 img {
    height: 100% !important;
    border-radius: 0px !important;
  }
  .mission-section.font-face-sh .container {
    padding: 0px;
  }
  .personal-details.font-face-sh.py-5.position-fixed {
    padding: 0px 10px;
  }
  .btn.bg-branding-2.border-0.rounded-pill.py-2.lh-2.desktop-btn.text-light.position-relative.d-flex.align-items-center.justify-content-center span {
    font-size: 14px;
  }
  .subscription-plan-price_term.text-black.lh-1.mt-3.ms-1 {
    margin-top: 0px !important;
  }
  .subscription-plan-selector-entries.d-flex.align-items-center ul {
    margin-left: 0px !important;
  }
  .subscription-plan-selector-entries.d-flex.align-items-center div {
    margin: 12px auto;
  }
  .subscription-plan-emphasis.text-white.d-inline-block.lh-normal.position-absolute.start-0.end-0.m-auto {
    width: 100px !important;
  }
  .logo.col-sm-6.col-md-6.col-lg-6.col-6 .start-0.only-responsive.d-none , .logo.col-sm-6.col-md-6.col-lg-6.col-6 .start-0 , .footer-logo img , .logo-red.col-md-12 img {
    width: 70px !important;
    /* margin-left: -30px; */
  }
  .prize-block .prize-photo.col-sm-12.col-md-12.col-lg-12.text-center.p-5.position-relative {
    height: 230px !important;
  }
  .story-block.w-75 img {
    width: 100% !important;
  }
  .subscription-plan-emphasis.branding-1.d-inline-block.lh-normal.position-absolute.start-0.end-0.m-auto.bg-branding-2 {
    width: 90px !important;
  }
  .position-relative .subscription-plan-emphasis.branding-2.d-inline-block.lh-normal.position-absolute.start-0.end-0.m-auto {
    width: 90px !important;
  }
  .story-block-stats p strong::before {
    top: -70%;
  }
  
  .inner-header.row.d-flex.align-items-center .start-0.not-responsive {
    display: none;
  }
  .story-block-stats.text-dark strong {
    font-size: 14px !important;
  }
  .story-block-testimonial p {
    font-size: 16px !important;
  }
  .custom-arrow.next-arrow::before {
    right: -40px;
  }
  .custom-arrow.prev-arrow::before {
    left: -40px;
  }
  .inner-header.row.d-flex.align-items-center .start-0.only-responsive {
    display: block !important;
  }
  .team-fixtures .start-0 {
    left: 0 !important;
    width: 50% !important;
    padding: 20px 0px;
  }
  .notfound a {
    width: 40% !important;
    margin: 20px 10px !important;
    font-size: 16px !important;
  }
  .testimonial-slider {
    width: 100% !important;
  }
  /* .ticket-section.pb-5 {
    padding-bottom: 0px !important;
  } */
  .team-fixtures.bg-white.rounded-4.py-5.px-4 {
    padding: 15px !important;

  }
  .fixtures {
    padding-top: 10px !important;
  }
  .description-data.py-5::before {

    background: #fff;
    content: "";
    height: 100%;
    left: 100%;
    position: absolute;
    top: 0;
    transform: translateX(-90%);
    width: 170vw;
    z-index: -9;

}
  .mission-section  {
    padding-top: 70px !important;
  }
  .notfound h1 {
    font-size: 28px !important;
  }
  .notfound h5 {
    font-size: 18px !important;
  }
  .footer-section::after {
    background-size: cover;
    height: 100%;
  }
  .notfound img {
    width: 80% !important;
  }
  .box-logo.bg-dark.rounded-5.py-5.px-3.text-center.shadow-sm.position-relative {
    width: 100%;
    margin: 0 auto;
  }
  .logo-thank-you .share-icons.w-50 {
    width: 70% !important;
  }
  .container-fluid.home-left-side.mb-md-0.position-relative.thankyou-header {
    padding-top: 20px !important;
  }
  .container-fluid.thank-home.home-left-side.mb-md-0 {
    padding-top: 450px !important;
    padding-bottom: 40px !important;
  }
  .btn.thankyou-page-button.text-uppercase.bg-branding-1.border-0.rounded-pill.py-3.px-5.lh-1.text-light {
    padding: 20px !important;
  }
  .footer-section::after {
    background-size: cover;
  }


  #hubspot-messages-iframe-container.widget-align-right {
    display: none !important;
  }
  .steps-subscription-plan {
    padding-left: 20px;
    padding-right: 20px;
  }
  .home-footer {
    padding-bottom: 300px;
  }
  .subscription-plan-home {
  display: block;
}
  .free-entry-modal_title_block {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 0px !important;
  }
  .free-entry-modal_description_block {
    margin-top: 0px !important;
    max-width: 320px !important;
  }
  .free-entry-modal_title_block img.cross {
    right: 10px !important;
    top: -30px !important;
    width: 40px !important;
  }
  .personal-details .subscription-plan-container , .payment-details .subscription-plan-container {
    max-width: 330px !important;
    padding-left: 15px;
    padding-right: 15px;
  }
  .account-impact-section {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 991px) {
.desktop-menu {
  display: none !important;
}
.mobile-menu {
  display: flex !important;
}
.site-header {
  /* background-color: #151c36; */
  z-index: 99999;
position: relative;
}
.site-header.dashboard-header {
  background-color: #ffffff;
}
.account-navigation .navigation-item.settings {
  display: none !important;
}
.dashboard-menu {
  display: none !important;
}
.dashboard-header .MenuButton_firstLine, .dashboard-header .MenuButton_secondLine, .dashboard-header .MenuButton_thirdLine {
  background: #000;
}

.open .MenuButton_firstLine, .open .MenuButton_secondLine, .open .MenuButton_thirdLine {
  background: #fff;
}
.for-mobile {
  display: block !important;
}
}


@media only screen and (max-width: 767px) {
  .wt-100 {
    width: 100% !important;
  }
  .matchcard-logosblock {
    padding: 30px 15px !important;
  }
  .match-card {
    margin: 0 !important;
  }
  .matchcard-header img {
    width: 140px !important;
  }
  .desktop-btn {
    display: none;
  }
  .mobile-bottom {
    display: block !important;
  }
  .mobile-bottom button {
    width: 100% !important;
  }
  .hero-section {
    /* padding-bottom: 130px !important; */
  }
  .step-subscription-plan-selector ul {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    grid-gap: 60px 20px !important;
  }
  .stories-section-title {
    margin-top: 0px !important;
  }
  .stories-container {
    width: 100% !important;
  }
  .stories-container {
    width: 100% !important;
    border: none;
    padding: 0 !important;
    text-align: center;
  }
  .story-block-stats .d-flex.align-items-center {
    justify-content: center;
  }
  .story-block-testimonial h4 {
    margin-top: 30px;
  }
  .story-photo img {
    width: 108px !important;
    margin: 0 auto;
  }
  .curve-dot {
    bottom: -39px !important;
  }
  .story-block-inner {
    padding-bottom: 95px !important;
  }
  .slick-dots {
    bottom: -60px;
  }
  .prizes-section_container {
    width: 89% !important;
  }
  .prizes-section_blockcontainer_sec {
    margin-left: -24px;
    margin-right: -24px;
  }
  .prizes-section-prizes_container {
    margin-left: -24px !important;
    margin-right: -24px !important;
    margin-top: 20px !important;
  }
  .prizes-section-prizes_container h3 {
    font-size: 20px !important;
  }
  .prizes-section-prizes_container .col-md-6 {
    width: 50%;
  }
  .how-it-works_section .col-md-4 P {
    margin-top: 30px !important;
    margin-bottom: 40px;
  }
  .container-2 {
    max-width: 89% !important;
    margin: 0 auto;
  }
  .charity_section p.m-auto {
    margin: 0 !important;
    width: 100% !important;
    margin-top: 20px !important;
  }
  .charity_section .col-sm-12.col-md-12.col-lg-6.pe-5 {
    padding-right: 12px !important;
  }
  .charity_section button {
    width: 100% !important;
    margin-top: 30px !important;
    margin-bottom: 50px !important;
  }
  .bg-branding-green {
    width: 100% !important;
  }
  .container {
    padding: 0px 20px;
  }
  .prizes-section::before {
    width: 100vw;
    height: 97%;
  }
  .subscription-plan-container {
    max-width: 375px !important;
  }
  .step-subscription-plan-selector ul {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
  }
  .subscription-plan-price_currency {
    margin-top: 5px !important;
  }
  .ticket-title-sec img {
    height: 55px !important;
  }
  .ticket-title-sec h4 {
    font-size: 16px !important;
  }
  .ticket-title-sec {
    padding-bottom: 20px !important;
  }
  .StepSubscriptionPlan_topBackground {
    height: 300px !important;
    border-radius: 0px 0px 78px 78px;
    background-color: #49a2f1;
  }
  .StepSubscriptionPlan_topBackground path {
    display: none;
  }
  .subscription-plan-container .step-user-details-info {
    padding-left: 0px;
    margin-top: 0 !important;
  }
  .subscription-plan-container .step-user-details {
    padding-right: 0 !important;
    padding-left: 0;
  }
  .claim-methods button.btn {
    padding: 0.8rem 0.8rem !important;
    font-size: 0.8rem !important;
  }
  /*Card Payment Method*/
  .payment-card-modal-header.modal-header {
    right: 0;
    /* padding-top: 0; */
  }
  .payment-card-modal-header.modal-header .modal-title div {
    padding: 0 !important;
  }
  .payment-card-modal-body.modal-body {
    padding-left: 12px;
    padding-right: 12px;
  }
  .payment-card-modal-body.modal-body .row.mt-3 .col-6 .row.pe-2 {
    padding-right: 4px !important;
  }
  .payment-card-modal-body.modal-body .row.mt-3 .col-6 .row.ps-2 {
    padding-left: 4px !important;
  }
  .card-brand-logo {
    width: 50px !important;
    top: -5px !important;
  }
  .card-brand-logo-type {
    width: 40px !important;
  }
  .payment-card-number {
    font-size: 18px !important;
  }
  .payment-card-holdername {
    font-size: 18px !important;
  }
  .make-default-btn {
    width: 50px !important;
  }
  }

@media only screen and (max-width: 479px) {
  .steps-subscription-plan {
    padding-left: 20px;
    padding-right: 20px;
  }
  .free-entry-modal_title_block {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 0px !important;
  }
  .free-entry-modal_description_block {
    margin-top: 0px !important;
    max-width: 320px !important;
  }
  .free-entry-modal_title_block img.cross {
    right: 10px !important;
    top: -30px !important;
    width: 40px !important;
  }
  .personal-details .subscription-plan-container , .payment-details .subscription-plan-container {
    max-width: 330px !important;
    padding-left: 15px;
    padding-right: 15px;
  }
  .account-impact-section {
    padding-left: 20px;
    padding-right: 20px;
  }
  .payment-card-modal-footer.modal-footer .card-foot-inner {
    margin: 0;
    padding-top: 5px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .payment-card-modal-footer.modal-footer .card-foot-inner .btn {
    width: 50%;
  }
  .payment-card-modal-footer.modal-footer .card-foot-inner .row div {
    padding: 0;
  }
}

@media only screen and (max-width: 350px) {
  .account-impact-top_bg .position-absolute.start-0.end-0 {
    top: 0px !important;
  }
  .account-impact_logo.mt-3.text-center {
    margin: 0px !important;
  }
  .navigation-list a {
    font-size: 12px;
  }
}

.thankyou-page-button:hover {
  background-color: #000 !important;
}